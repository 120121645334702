<template>
  <div>
    <div class="topFix">
      <el-button type="info" @click="goReturn"
        ><i class="el-icon-arrow-left"></i>返回</el-button
      >
    </div>
    <div class="contMain" v-show="!tackPhoto" :style="{'margin-top':subject_id == 2&&shitiselsect!=''?'90px':''}">
      <div class="contMainTop">
        <h4 v-html="info.title"></h4>
        <p>
          <i v-if="subject_id == 2&&courseType==2"></i>
          <i v-else>本次考试共{{ info.question_num }}题，总分{{ info.total_score }}分，</i><i
            v-if="info.time_limit > 0"
            >考试限时{{ info.time_limit }}分钟。</i
          ><i v-if="info.time_limit == 0">考试不限时。</i>
          <i v-if="info.time_limit==-1">考试限时{{parseInt((info.end_time-info.start_time)/60)}}分钟。</i>
        </p>
        <template v-if="courseType != 2">
          <span v-if="number >= 0"
            >您还有 <b>{{ number }}</b> 次作答机会</span
          >
          <span v-if="number == -1">不限定次数作答</span>
        </template>
      </div>
      <div class="pl30 pr30 pb35">
        <h5 class="tit">考试要求</h5>
        <div class="textInfo" v-html="info.notice"></div>
      </div>
      <div class="pl30 pr30 pb35">
        <h5 class="tit">注意事项</h5>
        <div class="textInfo">
          1.考场内必须保持安静。考生不准吸烟，不准交头接耳、左顾右盼，不准打手势做暗号，不准夹带、偷看，不准传纸条，不准蓄意破坏考试机器，不得进行与考试无关的操作。<br />
          2.在线考试采用人脸识别系统，未通过识别的考生，应举手示意，及时向监考员报告，由考场工作人员进行处理。<br />
          3.应试人员不得要求监考员解释试题。<br />
          4.交卷时，考生须执行软件上的“交卷”操作，如考试时间截止，系统将执行强制交卷，未及时上传成功的附件，不计入作答结果。<br />
          5.考试结束，考生须依次退出考场，不准在考场逗留。
        </div>
      </div>
      <div class="pl30 pr30">
        <h5 class="tit">防作弊提示</h5>
        <div class="preventCheat">
          <span>
            <i class="iconfont icon-time1"></i>
            请您在答题过程中不要切换或关闭页面。一旦开始考试，计时将不会停止
          </span>
          <span v-if="preven.no_screen_cuts == 1">
            <i class="iconfont icon-tab"></i>
            本场考试开启了防切屏设置。在考试页面中，切屏操作将会失效
          </span>
          <span v-if="preven.screen_cuts_number > 0">
            <i class="iconfont icon-exit"></i>
            在您离开答题页面
            <b>{{ preven.screen_cuts_number }}</b>
            次后，系统将强制收卷，自动结束考试
          </span>
        </div>
      </div>
      <template v-if="subject_id == 2&&courseType==2">
        <div class="pl30 pr30">
          <h5 class="tit">选择考位</h5>
        <div class="shiyantihao">
          <p class="shiyantihaop">您的考位号：<span>{{ examroom_seat.seatno }}</span></p>
        </div>
        </div>
      </template>
      <div class="mb30 pl30">
        <el-checkbox v-model="read" class="fontSize16 color666"
          >我已阅读并知晓考试要求，保证本场考试由本人独立完成</el-checkbox
        >
      </div>
      <div class="textAlignC pb40">
        <template v-if="shout">
          <p style="text-align: center; line-height: 40px; font-size: 16px">距离开考</p>
          <div style="margin: 0 auto; width: 200px; height: 48px; text-align: center; line-height: 48px; font-size: 20px; color: #507FFF; border-radius: 24px; background: #DCE5FF">{{times}}</div>
        </template>

        <el-button v-else type="primary" round class="begin" @click="beginAnswer"
        >开始答题</el-button
        >
      </div>
    </div>
    <faceRecognition
      v-show="tackPhoto"
      :tackPhoto="tackPhoto"
      ref="faceRecognition"
    />
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :show-close="false"
      width="600px"
    >
      <div class="contMaindialog">
        <h5 class="contMaindialogh5">
          <img src="./assets/images/touxiang.png" />
        </h5>
        <h6 class="contMaindialogh6">本场考试已开启人脸识别验证</h6>
        <div class="contMaindialoghdiv">
          <p>接下来，您需要拍摄一张照片并上传，以验证是否本人参加考试。</p>
          <p>请确保考试设备的摄像头功能正常，并调整好环境亮度与姿势。</p>
        </div>
        <div class="btnWrap">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submityz()">开始验证</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { newpaperInfo, escalation,check } from "./assets/api";
import faceRecognition from "./faceRecognition"; //人脸识别组件
export default {
  name: "examRequire",
  components: { faceRecognition },
  data() {
    return {
      number: 0,
      read: false,
      info: {},
      preven: {},
      dialogVisible: false,
      tackPhoto: false,
      iSubmitPhoto: false, //是否已提交成功照片
      cpe_id: "",
      courseType: 1, //1是常规考试，2是小米认证考试
      shitiselsect: "",
      subject_id: 0,
      examroom_seat:{},
      timesdjs:null,
      chatime:0,
      shout:true
    };
  },
  computed:{
    times(){
        const minutes = parseInt((this.chatime / 60) % 60);
        const seconds = this.chatime % 60;
        const m = minutes < 10 ? "0" + minutes : minutes;
        const s = seconds < 10 ? "0" + seconds : seconds;
        return `${m} : ${s}`;
    }
  },
  mounted() {
    this.timesdjs = setInterval(()=>{
      if(this.chatime>0){
        this.shout = true
        this.chatime--
      }else{
        this.shout = false
        this.chatime=0
        clearInterval(this.timesdjs)
      }
    },1000)
    if (
      this.$route.query.course_id &&
      this.$route.query.id &&
      this.$route.query.cst_id &&
      this.$route.query.id
    ) {
      this.newpaperInfo(
        this.$route.query.paper_id,
        this.$route.query.course_id,
        this.$route.query.cst_id,
        this.$route.query.id
      );
    }
    if (this.$route.query.courseType) {
      this.courseType = this.$route.query.courseType;
    }
  },

  methods: {
    //异常上报（初始化）
    escalation() {
      const params = {
        course_paper_id: this.$route.query.id,
        cst_id: this.$route.query.cst_id,
      };
      escalation(params).then((res) => {
        this.cpe_id = res.data.cpe_id;
        window.sessionStorage.setItem("cpe_id", this.cpe_id);
      });
    },
    submityz() {
      this.dialogVisible = false;
      this.tackPhoto = true;
      this.$refs.faceRecognition.handleOpen();
    },
    goReturn() {
      if (this.tackPhoto) {
        this.tackPhoto = false;
        this.$refs.faceRecognition.stopNavigator(); // 关闭摄像头
      } else {
        this.$router.go(-1);
      }
    },
    beginAnswer() {
      if (!this.read) {
        this.$message.warning("请勾选“我已阅读并知晓考试要求”");
      } else if (this.number == 0) {
        this.$message.warning("考试次数已用尽");
      }else {
        const params = {
          course_paper_id: this.$route.query.id,
          cst_id: this.$route.query.cst_id,
        };
        check(params).then(res=>{
          if(res.code==0){
            if (this.preven.face_verification == 1) {
              if (this.iSubmitPhoto) {
                const query = Object.assign({}, this.$route.query, {
                  no_screen_cuts: this.preven.no_screen_cuts,
                  shitiselsect: this.shitiselsect,
                });
                this.$router.push({ path: "/studentsExam", query });
              } else {
                this.dialogVisible = true;
              }
            } else {
              const query = Object.assign({}, this.$route.query, {
                no_screen_cuts: this.preven.no_screen_cuts,
                shitiselsect: this.shitiselsect,
              });
              this.$router.push({ path: "/studentsExam", query });
            }
          }
        })

      }
    },
    newpaperInfo(id, course_id, cst_id, newcourse_paper_id) {
      const params = {
        id,
        course_id,
        cst_id,
        newcourse_paper_id,
      };
      newpaperInfo(params).then((res) => {
        if (res.code == 0) {
          this.number = res.data.exams_number;
          this.info = res.data;
          this.chatime = res.data.start_time - res.data.now_time
          if( this.chatime>0 ){this.shout = true}else{this.shout = false}
          this.shitiselsect = res.data.examroom_seat.tag
          this.examroom_seat=res.data.examroom_seat
          this.preven = res.data.preven;
          this.subject_id = res.data.subject_id;
          if (this.preven.face_verification == 1) {
           // if(this.subject_id == 2&&this.courseType==2){
           //   this.dialogVisible = false;
           // } else{
           //   this.dialogVisible = true;
           // }
            this.dialogVisible = true;
            this.escalation();
          } else {
            this.dialogVisible = false;
          }
        } else {
          if (this.$route.query.courseType == 2) {
            this.$router.push({
              path: "/MyExam",
              query: {},
            });
          } else {
            this.$router.push({
              path: "/newlearn",
              query: {
                cst_id: this.$route.query.cst_id,
                course_id: this.$route.query.course_id,
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.shiyantihao {
  width: 1360px;
  margin: 24px auto 21px;
  font-size: 0;
  .shiyantihaop{
    font-size:16px;
    color: #333333;
    line-height: 32px;
    display: inline-block;
    height: 32px;
    span{
      font-weight: bold;
      font-size:20px;
      color: #507FFF;
    }
  }
  .shiyantihaoselect{
    display: inline-block;
    font-size:13px;
    color: #507FFF;
    line-height: 30px;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid #507FFF;
    text-align: center;
    margin-left:19px;
    &:hover{
      background: #507FFF;
      color: #fff;
    }
  }
}

.topFix {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 20px;
  z-index: 2;
  button {
    background: #f3f4f7;
    border-color: #f3f4f7;
    color: #888;
    padding: 8px 15px;
  }
}
.contMain {
  width: 1360px;
  margin: 82px auto 20px;
  background: #fff;
  border-radius: 14px 14px 8px 8px;
  .contMainTop {
    border-radius: 14px 14px 0 0;
    height: 107px;
    padding: 26px 30px 0;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    background-image: -webkit-linear-gradient(45deg, #6d94ff, #4879ff);
    background-image: -o-linear-gradient(45deg, #6d94ff, #4879ff);
    background-image: -moz-linear-gradient(45deg, #6d94ff, #4879ff);
    background-image: linear-gradient(45deg, #6d94ff, #4879ff);
    span {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      background: #fff;
      border-radius: 40px;
      line-height: 40px;
      padding: 0 20px;
      font-size: 16px;
      b {
        color: #507fff;
      }
    }
    h4 {
      color: #fff;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
    p {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 20px;
      padding-left: 14px;
      position: relative;
    }
    p::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.6);
    }
  }
  .contMainTop::after {
    position: absolute;
    content: "";
    width: 85px;
    height: 94px;
    background: url("./assets/images/xs_bk01.png") no-repeat;
    right: 34px;
    bottom: -13px;
  }
  .tit {
    font-size: 16px;
    margin-bottom: 15px;
    position: relative;
    padding-left: 11px;
    line-height: 21px;
  }
  .tit::before {
    position: absolute;
    content: "";
    width: 3px;
    height: 17px;
    background: #507fff;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .textInfo {
    font-size: 16px;
    line-height: 32px;
  }
  .preventCheat {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px;
    span {
      width: 420px;
      padding: 25px 80px 0;
      box-sizing: border-box;
      height: 160px;
      border-radius: 5px;
      background: #f5f7ff;
      text-align: center;
      font-size: 16px;
      line-height: 28px; margin-right: 20px;
      b {
        color: #507fff;
        font-weight: normal;
      }
      i {
        color: #507fff;
        font-size: 44px;
        margin-bottom: 12px;
        display: block;
        line-height: 44px;
      }
    }
  }
  .begin {
    width: 200px;
    height: 48px;
    font-size: 16px;
    border-radius: 40px;
  }
}
.contMaindialog {
  .contMaindialogh5 {
    text-align: center;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .contMaindialogh6 {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    text-align: center;
    padding-bottom: 8px;
  }
  .contMaindialoghdiv {
    padding-left: 70px;
    padding-bottom: 30px;
    p {
      color: #333;
      font-size: 14px;
      line-height: 24px;
      margin-top: 2px;
      position: relative;
      padding-left: 16px;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 6px;
        height: 6px;
        background: #bdc1cb;
        margin-top: -3px;
        border-radius: 50%;
      }
    }
  }
  .btnWrap {
    text-align: center;
    .el-button--default {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      color: #666;
      min-width: 96px;
      &:hover,
      &:focus {
        background: #ddd;
      }
    }
  }
}
</style>
