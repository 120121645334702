<template>
  <div class="faceRecognition">
    <div class="camera-box">
      <div class="public-mask mask-grey-bg">
        <div class="mask-main camera-main">
          <div class="camera-leftpar" v-show="!isPhoto">
            <div class="camera-left">
              <video id="videoCamera" v-show="isHaveOs"></video>
              <img src="./assets/images/zwsxt.png" alt="" v-show="!isHaveOs" />
            </div>
            <div class="camera-leftbut">
              <el-button
                type="primary"
                class="save-camera-btn"
                round
                @click="drawImage"
                >拍照验证</el-button
              ><br />
              <el-button type="text" class="problem" @click="showProblem"
                >常见问题</el-button
              >
            </div>
          </div>
          <div class="camera-right" v-show="isPhoto">
            <div class="camera-img-box">
              <div class="small-img">
                <img
                  v-if="imgSrc"
                  :src="imgSrc"
                  style="width: 260px; height: 260px"
                />
                <canvas
                  id="canvasCamera"
                  class="canvas"
                  :width="videoWidth"
                  :height="videoHeight"
                  style="display: none"
                ></canvas>
              </div>
            </div>
            <div class="camera-leftbut">
              <div class="btnWrap">
                <el-button round @click="closeCameraMask()">重新拍照</el-button>
                <el-button
                  type="primary"
                  class="save-camera-btn"
                  round
                  @click="uploadPicture"
                  >开始验证</el-button
                >
              </div>
              <el-button type="text" class="problem" @click="showProblem"
                >常见问题</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="faceRecognitiondrawer">
      <el-drawer
        title="常见问题"
        :visible.sync="drawer"
        size="40%"
        direction="rtl"
      >
        <div class="faceRecognitiondrawerdiv">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item name="1">
              <template slot="title">
                <p class="faceRecognitiondrawerdivp">
                  常见的错误提示语解决办法？
                </p>
              </template>
              <div class="faceRecognitiondrawerdivchild">
                <p>
                  错误提示：摄像头未开启<br />解决办法：请允许网站使用你的摄像头，或更换摄像头功能正常的设备。若已经允许，请点击【拍照验证】按钮重试。
                </p>
                <p>
                  错误提示：请联系相关老师录入档案照片<br />解决办法：请联系老师在学生档案中为您上传采集照片，然后再进入此页面验证。
                </p>
                <p>
                  错误提示：验证失败，请重新拍照上传<br />解决办法：请确保照片中的面部完整、清晰、无遮挡。
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item name="2">
              <template slot="title">
                <p class="faceRecognitiondrawerdivp">
                  如何授权网站使用摄像头？
                </p>
              </template>
              <div class="faceRecognitiondrawerdivchild">
                <p>
                  首先请检查电脑系统的隐私设置中，是否开启了摄像头的访问权限。然后请检查浏览器的隐私设置中，是否允许网站使用摄像头。请确保他们全部开启。
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template slot="title">
                <p class="faceRecognitiondrawerdivp">
                  摄像头已打开，但是显示黑屏？
                </p>
              </template>
              <div class="faceRecognitiondrawerdivchild">
                <p>
                  请打开设备管理器，尝试更新或卸载重装照相机的驱动程序。如果仍无法解决，请及时更换设备或联系相关老师。
                </p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-drawer>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :show-close="false"
      width="600px"
    >
      <div class="contMaindialog">
        <h5 class="contMaindialogh5">
          <img src="./assets/images/touxiang_error.png" />
        </h5>
        <h6 class="contMaindialogh6">人脸识别验证失败</h6>
        <div class="contMaindialoghdiv">
          <p>
            未找到您的档案照片，无法进行人脸识别验证。<br />请联系相关老师录入。
          </p>
        </div>
        <div class="btnWrap">
          <el-button type="primary" @click="dialogVisible = false"
            >我知道了</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Md5 from "md5";
import { getCookie } from "@/assets/js/cookie.js";
import { changeStr, pinjie, objKeySort } from "@/assets/js/sign.js";
import axios1 from "axios";
import Vue from "vue";
var instance = axios1.create({
  baseURL: "",
  timeout: 5000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    "Uxb-User-Agent": "webVersion:v1.0.0",
  }
});
Vue.prototype.instance = instance;
export default {
  name: "faceRecognition",
  data() {
    return {
      imgSrc: undefined,
      os: false, //控制摄像头开关
      thisVideo: null,
      thisContext: null,
      thisCancas: null,
      videoWidth: 260,
      videoHeight: 260,
      isHaveOs: false, //true为有摄像头，false没有摄像头
      drawer: false,
      activeName: "1",
      isPhoto: false, //true为已拍照，false没有拍照
      dialogVisible: false,
      fileSignData: {
        siteid: getCookie("siteid"),
        stime: "",
        sign: "",
      },
    };
  },
  destroyed() {
    this.stopNavigator(); // 关闭摄像头
  },
  methods: {
    showProblem() {
      this.drawer = true;
    },
    // 打开照相机弹窗
    handleOpen() {
      this.getCompetence();
    },
    // 调用摄像头权限
    getCompetence() {
      //必须在model中render后才可获取到dom节点,直接获取无法获取到model中的dom节点
      this.$nextTick(() => {
        const _this = this;
        this.os = false; //切换成关闭摄像头
        this.thisCancas = document.getElementById("canvasCamera"); //这里是需要截取的canvas的Id名称
        this.thisContext = this.thisCancas.getContext("2d");
        this.thisVideo = document.getElementById("videoCamera");
        // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
        if (navigator.mediaDevices === undefined) {
          navigator.menavigatordiaDevices = {};
        }
        // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
        // 使用getUserMedia，因为它会覆盖现有的属性。
        // 这里，如果缺少getUserMedia属性，就添加它。
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function (constraints) {
            // 首先获取现存的getUserMedia(如果存在)
            let getUserMedia =
              navigator.webkitGetUserMedia ||
              navigator.mozGetUserMedia ||
              navigator.getUserMedia;
            // 有些浏览器不支持，会返回错误信息
            // 保持接口一致
            if (!getUserMedia) {
              return Promise.reject(
                new Error("getUserMedia is not implemented in this browser")
              );
            }
            // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
            return new Promise(function (resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          };
        }
        const constraints = {
          audio: false,
          video: {
            width: _this.videoWidth,
            height: _this.videoHeight,
            transform: "scaleX(-1)",
          },
        };
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function (stream) {
            // 旧的浏览器可能没有srcObject
            if ("srcObject" in _this.thisVideo) {
              _this.thisVideo.srcObject = stream;
            } else {
              // 避免在新的浏览器中使用它，因为它正在被弃用。
              _this.thisVideo.src = window.URL.createObjectURL(stream);
            }
            _this.thisVideo.onloadedmetadata = function (e) {
              _this.thisVideo.play();
            };
            _this.isHaveOs = true;
          })
          .catch((err) => {
            _this.isHaveOs = false;
            if(!sessionStorage.getItem('hosttype')){
              _this.$message({message: "没有开启摄像头权限或浏览器版本不兼容.",type: "warning",});
            }
          });
      });
    },
    //绘制图片
    drawImage() {
      if (this.isHaveOs) {
        // 点击，canvas画图
        this.thisContext.drawImage(
          this.thisVideo,
          0,
          0,
          this.videoWidth,
          this.videoHeight
        );
        this.imgSrc = this.thisCancas.toDataURL("image/png");
        this.isPhoto = true;
      } else {
        this.$message({
          message: "没有开启摄像头权限或浏览器版本不兼容.",
          type: "warning",
        });
      }
    },
    //base64图片转化为二进制文件流
    base64ToFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let file = new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
      return file;
    },
    // 上传照相机图片
    uploadPicture() {
      const formData = new FormData();
      let _file = this.base64ToFile(this.imgSrc, "file");
      formData.append("file", _file);
      this.fileSignData.siteid=getCookie("siteid")
      this.fileSignData.stime=parseInt(new Date().getTime() / 1000)
      this.fileSignData.sign=Md5(changeStr(pinjie(objKeySort(this.fileSignData))))
      formData.append("siteid", this.fileSignData.siteid);
      formData.append("stime", this.fileSignData.stime);
      formData.append("sign",this.fileSignData.sign);
      formData.append("cpe_id",this.$parent.cpe_id);
      this.instance.post("/api/newpaper/v1/face/web", formData).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("验证成功！");
          this.$parent.iSubmitPhoto = true;
          this.$parent.tackPhoto = false;
          this.stopNavigator(); // 关闭摄像头
        } else {
          if (
            res.data.message ==
            "未找到您的档案照片，无法进行人脸识别验证。请联系相关老师录入。"
          ) {
            this.dialogVisible = true;
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },
    //清空画布
    clearCanvas(id) {
      let c = document.getElementById(id);
      let cxt = c.getContext("2d");
      cxt.clearRect(0, 0, c.width, c.height);
    },
    //重置画布
    resetCanvas() {
      this.imgSrc = "";
      this.clearCanvas("canvasCamera");
    },
    //关闭摄像头
    stopNavigator() {
      if (this.thisVideo && this.thisVideo !== null) {
        this.thisVideo.srcObject.getTracks()[0].stop();
        this.os = true; //切换成打开摄像头
      }
    },
    // 关闭照相机弹窗
    closeCameraMask() {
      this.isPhoto = false; // 关闭照相机弹窗
      this.resetCanvas(); // 重置画布
      // this.stopNavigator(); // 关闭摄像头
      //this.getDetailList(); // 重新获取一下List，此方法不再书写
    },
  },
};
</script>
<style lang="scss" scoped>
.faceRecognition {
  padding-top: 82px;
  width: 1360px;
  margin: 0 auto;
  .camera-box {
    position: relative;
    height: calc(100vh - 130px);
    background: #fff;
  }
  .camera-main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .camera-leftpar {
    .camera-left {
      width: 270px;
      height: 270px;
      padding: 10px 0 0 10px;
      border-radius: 50%;
      border: 1px solid #507fff;
      margin: 0 auto;
      video {
        border-radius: 50%;
        object-fit: cover;
        width: 260px;
        height: 260px;
      }
      img {
        border-radius: 50%;
        width: 260px;
        height: 260px;
      }
    }
  }
  .camera-leftbut {
    padding-top: 40px;
    text-align: center;
    .save-camera-btn {
      min-width: 200px;
    }
    .problem {
      color: #666 !important;
      min-width: 200px;
      margin-top: 10px;
    }
  }
  .camera-img-box {
    width: 270px;
    height: 270px;
    padding: 10px 0 0 10px;
    border-radius: 50%;
    border: 1px solid #507fff;
    margin: 0 auto;
    img {
      border-radius: 50%;
      width: 260px;
      height: 260px;
    }
  }
  .btnWrap {
    text-align: center;
    white-space: nowrap;
    .el-button--default {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      color: #666;
      min-width: 200px;
      &:hover,
      &:focus {
        background: #ddd;
      }
    }
  }
}
.contMaindialog {
  .contMaindialogh5 {
    text-align: center;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .contMaindialogh6 {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    text-align: center;
    padding-bottom: 8px;
  }
  .contMaindialoghdiv {
    padding-bottom: 30px;
    p {
      color: #333;
      font-size: 14px;
      line-height: 24px;
      margin-top: 2px;
      text-align: center;
    }
  }
  .btnWrap {
    text-align: center;
    .el-button--default {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      color: #666;
      min-width: 96px;
      &:hover,
      &:focus {
        background: #ddd;
      }
    }
  }
}
</style>
